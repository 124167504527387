import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

const SiteMap = () => (
	<Layout>
		<SEO title="Akebia Medical Sitemap" />
		<div className="section section-half sitemap">
			<div className="container">
				<div className="row">
					<div className="col">
						<h1>Akebia Medical Sitemap</h1>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="divider"></div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-4 col-lg-5">
						<a className="sitemap-link h2" href="/resources">
							Resources
						</a>
						<a className="" href="/resources#disease-education">
							Disease Education
						</a>
						<ul>
							<li>
								<a href="/interactive-infographic/" rel="noreferrer" target="_blank">Interactive Infographic: CKD-MBD</a>
							</li>
							<li>
								<a href="https://d2zzqzqmnbfbm9.cloudfront.net/download/5585/" rel="noreferrer" target="_blank">Infographic: Anemia Due to CKD</a>
							</li>
							<li>
								<a href="https://d2zzqzqmnbfbm9.cloudfront.net/download/5584/" rel="noreferrer" target="_blank">Infographic: Burden of Anemia Due to CKD</a>
							</li>
							<li>
								<a href="https://d2zzqzqmnbfbm9.cloudfront.net/download/7066/" rel="noreferrer" target="_blank">Infographic: Phosphate Management in Patients with CKD on Peritoneal Dialysis</a>
							</li>
						</ul>
						<a className="" href="/resources#congress-activity">
							Congress Activity
						</a>
						<a className="" href="/resources#publication">
							Publications
						</a>
						<a className="" href="/resources#professional-resources">
							Professional Resources
						</a>
						<a className="" href="/resources#medical-information">
							Medical Information
						</a>
					</div>
					<div className="col-sm-12 col-md-4 col-lg-3">
						<a className="sitemap-link h2" href="/our-science/kidney-disease">
							Science
						</a>
						<p>
							<a href="/our-science/kidney-disease">Kidney Disease</a>
						</p>
						<p>
							<a href="/our-science/approved-products">Approved Products</a>
						</p>
						<ul>
							<li>
								<a className="" href="/our-science/approved-products#Auryxia">
									Auryxia<sup>&reg;</sup>
								</a>
							</li>
							<li>
								<a className="" href="/our-science/approved-products#Vafseo">
									Vafseo<sup>&reg;</sup>
								</a>
							</li>
						</ul>
						<p className="">
							<a href="/our-science/pipeline">Pipeline</a>
						</p>
						<p className="push-bottom">
							<a href="/our-science/anemia-due-to-ckd">Anemia Due to CKD</a>
						</p>
						<a className="h2" href="/our-science/pipeline#clinical-programs">
							Clinical Programs
						</a>
						{/* <p>
							<a href="/clinical-tool/" rel="noreferrer" target="_blank">Vadadustat</a>
						</p>
						<ul>
							<li>
								<a href="/clinical-tool/global-phase-3-non-dialysis.html" rel="noreferrer" target="_blank">PRO<sub>2</sub>TECT</a>
							</li>
							<li>
								<a href="/clinical-tool/global-phase-3-dialysis.html" rel="noreferrer" target="_blank">INNO<sub>2</sub>VATE</a>
							</li>
						</ul> */}
					</div>
					<div className="col-sm-12 col-md-4 col-lg-4">
						<a className="sitemap-link h2" href="/services">
							Services
						</a>
						<p>
							<a className="" href="/services#medical-information">
								Medical Information
							</a>
						</p>
						<p>
							<a className="" href="/services#medical-education-grants">
								Medical Education Grants
							</a>
						</p>
						<ul>
							<li>
								<a href="https://grants.akebia.com/en/" rel="noreferrer" target="_blank">Medical Education Grant Request Portal</a>
							</li>
						</ul>
						<p className="push-bottom">
							<a className="" href="/services#external-sponsored-research">
								External Sponsored Research
							</a>
						</p>
						<Link
							to="/contact-us"
							className="h2"
							activeClassName=""
							partiallyActive={true}
							state={{ filter: 'mirf' }}
						>
							Contact
						</Link>
						<p>
							<Link
								to="/contact-us"
								className=""
								activeClassName="header-link-active"
								partiallyActive={true}
								state={{ filter: 'mirf' }}
							>
								Medical Information Request
							</Link>
						</p>
						<p className="push-bottom">
							<Link
								to="/contact-us"
								className=""
								activeClassName="header-link-active"
								partiallyActive={true}
								state={{ filter: 'msl' }}
							>
								Connect with a Field Medical Expert
							</Link>
						</p>
						<a className="sitemap-link h2" href="/congress">
							Congress
						</a>
						<Link
							to="/congress"
							className=""
							activeClassName="header-link-active"
							partiallyActive={true}
						//state={{ filter: 'Disease Education' }}
						>
							Akebia at Congresses
						</Link>
					</div>
				</div>
				<div className="row row-push-top">
					<div className="col-sm-12 col-md-6 col-lg-6">

					</div>
					<div className="col-sm-12 col-md-3 col-lg-3">

					</div>
				</div>
			</div>
		</div>
	</Layout>
);

export default SiteMap;
