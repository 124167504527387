import React, { useState } from "react";
import { Link } from "gatsby";
import ModalSignUp from "./modalSignUp";

const Menu = () => {
	const [menuOpen, setMenuOpen] = useState(false);

	const [showSignUp, setShowSignUp] = useState(false);

	function showSignupForm() {
		//alert("Hi Bob!");
		setShowSignUp(true);
	}

	return (
		<>
			<ul className="header-menu-list">
				<li className="header-link">
					<Link
						to="/resources"
						activeClassName="header-link-active"
						partiallyActive={true}
					//state={{ filter: 'Disease Education' }}
					>
						Resources
					</Link>
				</li>
				<li
					className="header-link"
					onMouseOver={() => setMenuOpen(true)}
					onMouseOut={() => setMenuOpen(false)}
					onFocus={() => setMenuOpen(true)}
					onBlur={() => setMenuOpen(true)}
					// eslint-disable-next-line
					role="menuitem"
					tabIndex={-1}
				>
					<Link
						to="/our-science/kidney-disease"
						activeClassName="header-link-active"
						partiallyActive={true}
						onFocus={() => setMenuOpen(true)}
						onBlur={() => setMenuOpen(false)}
						onClick={() => setMenuOpen(false)}
					>
						Science
					</Link>
					<div className={`header-menu ${menuOpen ? "active" : ""}`}>
						<Link
							className="header-menu-link"
							to="/our-science/kidney-disease"
							activeClassName="header-menu-link-active"
							partiallyActive={true}
						>
							Kidney Disease
						</Link>
						<Link
							className="header-menu-link"
							to="/our-science/approved-products"
							activeClassName="header-menu-link-active"
							partiallyActive={true}
						>
							Approved Products
						</Link>
						<Link
							className="header-menu-link"
							to="/our-science/pipeline"
							activeClassName="header-menu-link-active"
							partiallyActive={true}
						>
							Pipeline
						</Link>
						<Link
							className="header-menu-link"
							to="/our-science/anemia-due-to-ckd"
							activeClassName="header-menu-link-active"
							partiallyActive={true}
						>
							Anemia Due to CKD
						</Link>
					</div>
				</li>
				<li className="header-link">
					<Link
						to="/congress"
						activeClassName="header-link-active"
						partiallyActive={true}
					>
						Congress
					</Link>
				</li>
				<li className="header-link">
					<Link
						to="/services"
						activeClassName="header-link-active"
						partiallyActive={true}
					>
						Services
					</Link>
				</li>
				<li className="header-link">
					<Link
						to="/contact-us"
						activeClassName="header-link-active"
						partiallyActive={true}
					>
						Contact
					</Link>
				</li>
				<li className="header-link">
					<button
						onClick={e => showSignupForm(e)}
						className=""
					>
						Stay Informed
					</button>
				</li>
			</ul>
			<ModalSignUp
				isOpen={showSignUp}
				onRequestClose={setShowSignUp}
			/>
		</>
	);
};

export default Menu;
