import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import SEO from "../../components/seo";
import Hero from "../../components/hero";
import ModalExemptSeries from "../../components/modalExemptSeries";
import ResourceCard from "../../components/resources/resourceCard";
import References from "../../components/references";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ModalGame from "../../components/modalGame";
import ModalSingleVideo from "../../components/modalSingleVideo";

import kidneys from "../../images/anemia-due-to-ckd/hero_bg.jpg";
import iconUnlock from "../../images/anemia-due-to-ckd/icon_unlock.png";
import iconKidneysWhite from "../../images/anemia-due-to-ckd/icon_kidneys_white.png";

import iconCardio from "../../images/anemia-due-to-ckd/icon_cardio.png";
import iconCKD from "../../images/anemia-due-to-ckd/icon_ckd.png";
import iconPerson from "../../images/anemia-due-to-ckd/icon_person.png";
import iconIV from "../../images/anemia-due-to-ckd/icon_Iv.png";
import iconAmbulance from "../../images/anemia-due-to-ckd/icon_ambulance.png";

import videoBurden from "../../videos/Akebia_MOD_Animation_01_Burden_of_Anemia_due_to_CKD.mp4";

import iconKidneyDrop from "../../images/anemia-due-to-ckd/icon_kidney_drop.png";
import iconFE from "../../images/anemia-due-to-ckd/icon_fe.png";
import iconBone from "../../images/anemia-due-to-ckd/icon_bone.png";

import hero2 from "../../images/anemia-due-to-ckd/hero2.jpg";

import videoPathogenesis from "../../videos/anemia_due_to_ckd_patho_combined.mp4";

var lastScrollTop = 0;

var getElementLocation = function(sel) {
	let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
	let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
	var els = document.querySelectorAll(sel);
	els.forEach((el, idx) => {
		var rect = el.getBoundingClientRect();
		var t = rect.top;
		var p = t / vh;
		//console.log("EL Top: " + rect.top + " (VH: " + vh + ") [" + p + "%]");
		if (p <= 0.7) {
			el.classList.add('on');
		}
	});
};

var fixSubNavMenu = function(sel) {
	let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
	let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
	var els = document.querySelectorAll(sel);
	els.forEach((el, idx) => {
		var rect = el.getBoundingClientRect();
		var t = rect.top;
		var p = t / vh;
		//console.log("EL Top: " + rect.top + " (VH: " + vh + ") [" + p + "%]");
		if (p <= 0.7) {
			el.classList.add('on');
		}
	});
};

const AnemiaCKDPage = () => {
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [openVideoModalHIF, setOpenVideoModalHIF] = useState(false);
	const [openVideoModalOrch, setOpenVideoModalOrch] = useState(false);

	const [videoDoc, setVideoDoc] = useState();
	const [videoDocHIF, setVideoDocHIF] = useState();
	const [videoDocOrch, setVideoDocOrch] = useState();

	const [, setVideoFile] = useState("");
	const [videoFileHIF, setVideoFileHIF] = useState("");
	const [videoFileOrch, setVideoFileOrch] = useState("");

	const [burdenDoc, setBurdenDoc] = useState();
	const [landscapeDoc, setLandscapeDoc] = useState();
	const [openGameModal, setOpenGameModal] = useState(false);
	const [gameDoc, setGameDoc] = useState();

	const videoBurdenRef = useRef(null);
	const videoBurdenBttnRef = useRef(null);
	const videoBurdenPosterRef = useRef(null);

	const videoPathoRef = useRef(null);
	const videoPathoBttnRef = useRef(null);
	const videoPathoPosterRef = useRef(null);
	
	const handleVideoBurdenClicky = (event) => {
		event.preventDefault();
		console.log('Video Burden Button/Poster Clicked!');
		const vidBurden = videoBurdenRef.current;
		const vidBurdenPoster = videoBurdenPosterRef.current;
		const vidPatho = videoPathoRef.current;
		vidPatho.pause();
		vidBurdenPoster.style.display = "none";
		if (vidBurden.paused) {
			vidBurden.play();
		} else {
			vidBurden.pause();
		}
	};

	const handleVideoPathoClicky = (event) => {
		event.preventDefault();
		console.log('Video Pathogenesis Button/Poster Clicked!');
		const vidPatho = videoPathoRef.current;
		const vidPathoPoster = videoPathoPosterRef.current;
		const vidBurden = videoBurdenRef.current;
		vidBurden.pause();
		vidPathoPoster.style.display = "none";
		if (vidPatho.paused) {
			vidPatho.play();
		} else {
			vidPatho.pause();
		}
	};

	useEffect(() => {
		videoBurdenRef.current.addEventListener('playing', function() {
			videoPathoRef.current.pause();
		});
		videoPathoRef.current.addEventListener('playing', function() {
			videoBurdenRef.current.pause();
		});
		videoBurdenBttnRef.current.addEventListener('click', handleVideoBurdenClicky);
		videoBurdenPosterRef.current.addEventListener('click', handleVideoBurdenClicky);
		videoPathoBttnRef.current.addEventListener('click', handleVideoPathoClicky);
		videoPathoPosterRef.current.addEventListener('click', handleVideoPathoClicky);
		//body.style.height = "500vh";
		// determine scroll direction
		document.addEventListener("scroll", function() {
			var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
			if (st > lastScrollTop) {
				//console.log("Scrolling Down");
				getElementLocation('.icon_flex, .bg_dot');
				//fixSubNavMenu('#sub_sub_nav');
			} else if (st < lastScrollTop) {
				//console.log("Scrolling Up");
			}
			lastScrollTop = st <= 0 ? 0 : st;
		}, false);
		//
		async function fetchData() {
			try {
				const result = await axios.get(
					"https://d2zzqzqmnbfbm9.cloudfront.net/documents?category=Disease%20Education"
				);
				if (!result.data.errors) {
					const videoData = result.data.data.filter(d => d.id === 5750)[0];
					setVideoDoc(videoData);
					setVideoFile(
						"https://akebia-file-sync.s3.amazonaws.com/" + videoData.filename__v
					);

					const burdenData = result.data.data.filter(d => d.id === 5584)[0];
					setBurdenDoc(burdenData);

					const landscapeData = result.data.data.filter(d => d.id === 5585)[0];
					setLandscapeDoc(landscapeData);

					const gameData = result.data.data.filter(d => d.id === 6518)[0];
					setGameDoc(gameData);

					const videoDataHIF = result.data.data.filter(d => d.id === 11355)[0];
					setVideoDocHIF(videoDataHIF);
					setVideoFileHIF(
						"https://akebia-file-sync.s3.amazonaws.com/" + videoDataHIF.filename__v
					);

					const videoDataOrch = result.data.data.filter(d => d.id === 14229)[0];
					setVideoDocOrch(videoDataOrch);
					setVideoFileOrch(
						"https://akebia-file-sync.s3.amazonaws.com/" + videoDataOrch.filename__v
					);
				}
			} catch (e) {
				// console.log(e);
			}
		}

		fetchData();
	}, []);



	return (
		<Layout contact>
			<SEO description="Discover the innate adaptive response to anemia due to CKD. Explore the burden, pathogenesis, and the science of the HIF pathways." title="Unlock the Power Within: Understanding Anemia Due to CKD" />
			<Hero background={kidneys} large={true} isAnemia={true}>
				<div className="col-sm-8">
					<img
						src={iconUnlock}
						alt="Unlock the Power Within Logo"
						style={{ width: "100%", height: "auto", 'maxWidth': "550px" }}
					/>
					<p className="hero-copy-unlock">
						Tap into the innate adaptive responses to anemia due to chronic kidney disease (CKD)
					</p>
				</div>
			</Hero>
			{/* Start Breadcrumbs */}
			<div className="section section-noPad blue-bg full-width ">
				<div className="container breadcrumbs breadcrumbs-anemia">
					<div className="row">
						<div className="col">
							<div className="crumb-container">
								<Link
									to="/"
								>
									Home
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/our-science/kidney-disease"
									className=""
								>
									Science
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/our-science/anemia-due-to-ckd"
									className="active"
								>
									Anemia Due to CKD
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrumbs */}
			<div className="row blue-bg full-width content-anemia">
				<div className="col">
					<div className="container">
						<div className="section header">
							<div className="row">
								<div className="col-sm-12">
									<h5>
										Did you know the human body already has its own physiological process to help address anemia due to CKD?
									</h5>
									<div className="table subheader">
										<div className="cell">
										<img
											src={iconKidneysWhite}
											alt="icon of kidneys"
											style={{ width: "206px", height: "auto", 'maxWidth': "206px" }}
										/>
										</div>
										<div className="cell">
											<p>
												Disruptions in oxygen sensing by the kidneys and dysregulation of hypoxia-inducible factor (HIF) activation, however, can blunt these innate adaptive responses and worsen anemia. In fact, it is estimated that 15% of all patients with CKD have anemia, with prevalence rising to approximately 85% when patients progress to dialysis.<sup>1-3</sup>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="container">
				<div className="row">
					<div className="col">
						<hr />
					</div>
				</div>
			</div> */}

			<div className="section section-anemia">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h3 className="section-heading">
								Patients with anemia due to CKD experience more than just fatigue; their condition is also associated with long-term poor clinical outcomes.<sup>4-8</sup>
							</h3>

							<div className="icon_flex">
								<div className="icon">
									<img
										src={iconCardio}
										alt="icon of heart with EKG"
										style={{ width: "100%", height: "auto", 'maxWidth': "128px" }}
									/>
									<p>Increased Risk of Cardiovascular Disease<sup>4</sup></p>
								</div>
								<div className="icon">
									<img
										src={iconCKD}
										alt="icon of kidneys with ckd"
										style={{ width: "100%", height: "auto", 'maxWidth': "128px" }}
									/>
									<p>Rapid CKD Progression<sup>5</sup></p>
								</div>
								<div className="icon">
									<img
										src={iconPerson}
										alt="icon of a fatigued person"
										style={{ width: "100%", height: "auto", 'maxWidth': "128px" }}
									/>
									<p>Reduced Quality of Life<sup>6</sup></p>
								</div>
								<div className="icon">
									<img
										src={iconIV}
										alt="icon of an IV"
										style={{ width: "100%", height: "auto", 'maxWidth': "128px" }}
									/>
									<p>Vascular Access Complications<sup>7</sup></p>
								</div>
								<div className="icon">
									<img
										src={iconAmbulance}
										alt="icon of an ambulance"
										style={{ width: "100%", height: "auto", 'maxWidth': "128px" }}
									/>
									<p>Higher Rates of Mortality and Hospitalization<sup>5,8</sup></p>
								</div>
							</div>

							<div className="video-container video-burden">
								<div className="table">
									<div className="cell">
										<p className="label">Video</p>
										<p className="title">Burden of Anemia</p>
										<p className="desc">
											Learn about the clinical burden of anemia due to CKD and how anemia can contribute to worsening of outcomes in patients with CKD.
										</p>
										<a
											ref={videoBurdenBttnRef}
											id="burden_video_bttn" 
											className="btn btn-primary videoBtn"
											href="#"
											target=""
											rel="noreferrer"
										>
											Watch Video
										</a>
									</div>
									<div className="cell">
										<div id="poster_burden" className="video_poster" ref={videoBurdenPosterRef}></div>
										<video ref={videoBurdenRef} controls width="558">
  											<source src={videoBurden} type="video/mp4" />
										</video>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col">
						<hr />
					</div>
				</div>
			</div>
			<div className="section section-anemia anemia-hif">
				<div id="bg_dot_container1" className="bg_dot_container">
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col">
							<h4 className="section-heading">Anemia due to CKD is more than just an erythropoietin and iron deficiency.<sup>1</sup></h4>
							<p className="fs-20">Limited erythropoietin production and impaired iron metabolism can result from disrupted oxygen sensing by the kidneys. This underlying mechanism of anemia due to CKD dysregulates HIF activation, a cell signaling pathway that leads to the expression of genes to coordinate red blood cell production<sup>1,9</sup></p>
							<h5>The HIF pathway is key to addressing the underlying mechanism and unlocking the innate adaptive responses to anemia<sup>9</sup>:</h5>

							<div className="icon_flex">
								<div className="icon">
									<img
										src={iconKidneyDrop}
										alt="icon of endogenous erythropoietin production in kidneys"
										style={{ width: "100%", height: "auto", 'maxWidth': "143px" }}
									/>
									<p>Stimulate endogenous erythropoietin production by renal erythropoietin-producing cells<sup>10</sup></p>
								</div>
								<div className="icon">
									<img
										src={iconFE}
										alt="icon of gastrointestinal iron uptake"
										style={{ width: "100%", height: "auto", 'maxWidth': "138px" }}
									/>
									<p>Enhance gastrointestinal iron uptake and mobilization from iron stores<sup>11</sup></p>
								</div>
								<div className="icon">
									<img
										src={iconBone}
										alt="icon of erythroid progenitor maturation"
										style={{ width: "100%", height: "auto", 'maxWidth': "131px" }}
									/>
									<p>Facilitate erythroid progenitor maturation and proliferation<sup>12</sup></p>
								</div>
							</div>

							<div className="video-container video-pathogenesis">
								<div className="table">
									<div className="cell">
										<p className="label">Video</p>
										<p className="title">Pathogenesis of Anemia</p>
										<p className="desc">
											Anemia due to CKD has a complex multifactorial pathogenesis. Recent scientific advancements in the therapeutic landscape of anemia due to CKD aim to address the underlying pathogenesis.
										</p>
										<a
											ref={videoPathoBttnRef}
											id="pathogenesis_video_bttn" 
											className="btn btn-primary videoBtn"
											href="#"
											target=""
											rel="noreferrer"
										>
											Watch Video
										</a>
									</div>
									<div className="cell">
										<div id="poster_pathogenesis" className="video_poster" ref={videoPathoPosterRef}></div>
										<video id="video_pathogenesis" controls width="558" ref={videoPathoRef}>
  											<source src={videoPathogenesis} type="video/mp4" />
										</video>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>	
			</div>

			<div className="hero hero-large hero-anemia2" style={{ 
				backgroundImage: `url(${hero2})`, // Use template literals to insert the image
				backgroundSize: 'cover', // Adjust background size as needed
				backgroundRepeat: 'no-repeat' // Prevent image repetition
			}}>
				<div id="bg_dot_container2" className="bg_dot_container">
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm-7">
							<p className="hero2-header">
								It’s time to help the body help itself.
							</p>
							<p className="hero2-copy">
								Enhance your knowledge about the HIF pathway and the scientific advancements in managing anemia due to CKD. 
							</p>
							<Link
								id="hero2-bttn" 
								className="hero2-bttn"
								to="/contact-us"
							>
								Connect With Our Medical Affairs Team
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div id="resources" className="section section-anemia anemia-resources bg-darkTeal">
				<div id="bg_dot_container3" className="bg_dot_container">
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col">
							<h2>
								Explore the Complexities of Anemia Due to CKD
							</h2>
							<p className="subhead">
								Anemia is a common complication of CKD and is a result of complex and multifactorial events that can contribute to poor clinical outcomes, such as cardiovascular events and mortality.<sup>1-8</sup>
							</p>

							<div className="anemia-resource-cards">
								{/* {videoDoc && (
									<ResourceCard
										callback={() => {
											setOpenVideoModal(true);
										}}
										resource={videoDoc}
									/>
								)} */}
								{landscapeDoc && (
									<ResourceCard
										callback={() => {
											window.open(
												"https://d2zzqzqmnbfbm9.cloudfront.net/download/" +
												landscapeDoc.id,
												"_blank"
											);
										}}
										resource={landscapeDoc}
									/>
								)}
								{burdenDoc && (
									<ResourceCard
										callback={() => {
											window.open(
												"https://d2zzqzqmnbfbm9.cloudfront.net/download/" +
												burdenDoc.id,
												"_blank"
											);
										}}
										resource={burdenDoc}
									/>
								)}
							</div>				
						</div>
					</div>

					<hr />

					<div className="row">
						<div className="col">
							<h2>
								Discover the Intricacies of the HIF Pathway
							</h2>
							<p className="subhead">
								HIF activation is the body's physiological response to hypoxia. This pathway coordinates erythropoiesis in patients with anemia due to CKD by increasing endogenous production of erythropoietin and enhancing iron metabolism.<sup>1,9-12</sup>
							</p>

							<div className="anemia-resource-cards">
								{videoDocHIF && (
									<ResourceCard
										callback={() => {
											setOpenVideoModalHIF(true);
										}}
										resource={videoDocHIF}
									/>
								)}
								{gameDoc && (
									<ResourceCard
										category="Game"
										callback={() => {
											setOpenGameModal(true);
										}}
										resource={gameDoc}
									/>
								)}
								{videoDocOrch && (
									<ResourceCard
										callback={() => {
											setOpenVideoModalOrch(true);
										}}
										resource={videoDocOrch}
									/>
								)}
							</div>						
						</div>
					</div>
				</div>
			</div>

			<div className="section section-anemia anemia-learn bg-orange">
				<div id="bg_dot_container4" className="bg_dot_container">
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm-6">
							<h2>Learn more about anemia due to CKD and the advancing science of the HIF pathway.</h2>
						</div>
						<div className="col-sm-6">
							<Link
								id="learn-more-bttn" 
								className="learn-more-bttn"
								to="/resources/"
							>
								Learn More
							</Link>
						</div>
					</div>
				</div>
				{/* <div id="bg_dot_container2" className="bg_dot_container">
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
					<div className="bg_dot"></div>
				</div> */}
			</div>
			

			<References isAnemia={true}>
				<p>
					<strong>1.</strong> Portolés J, et al. <em>Front Med (Lausanne)</em>. 2021;8:642296. <strong>2.</strong> United States Renal Data System. 2023 USRDS Annual Data Report: Epidemiology of Kidney Disease in the United States. National Institutes of Health, National Institute of Diabetes and Digestive and Kidney Diseases; Bethesda, MD; 2023. <strong>3.</strong> Stauffer ME, Fan T. <em>PLoS One</em>. 2014;9(1):e84943. <strong>4.</strong> Covic A, et al. <em>Adv Ther</em>. 2017;34:1662-1672. <strong>5.</strong> Portolés J, et al. B<em>MC Nephrol</em>. 2013;14:2. <strong>6.</strong> Eriksson D, et al. <em>BMC Nephrol</em>. 2016;17:97. <strong>7.</strong> Locham SS, et al. <em>J Vasc Surg</em>. 2018;68(2):E11-E12. <strong>8.</strong> Herzog CA, et al. <em>J Card Fail</em>. 2004;10:467-472. <strong>9.</strong> Haase VH. <em>Hemodial Int</em>. 2017;21:S110-S214. <strong>10.</strong> Wenger RH, et al. <em>Am J Physiol Renal Physiol</em>. 2010;298(6):F1287-F1296. <strong>11.</strong> Mastrogiannaki M, et al. <em>Blood</em>. 2013;122(6):885-892. <strong>12.</strong> Koury MJ, et al. <em>Nat Rev Nephrol</em>. 2015;11(7):394-410.
				</p>
			</References>
			{gameDoc && (
				<ModalGame
					isOpen={openGameModal}
					onRequestClose={() => {
						setOpenGameModal(false);
					}}
				/>
			)}
			{videoDoc && (
				<ModalExemptSeries
					isOpen={openVideoModal}
					onRequestClose={() => {
						setOpenVideoModal(false);
					}}
				/>
			)}
			{videoDocHIF && (
				<ModalSingleVideo
					isOpen={openVideoModalHIF}
					onRequestClose={() => {
						setOpenVideoModalHIF(false);
					}}
					playlist={[
						{
							video: videoFileHIF,
							title: videoDocHIF.title__v,
							description: videoDocHIF.medical_description__c,
							isWide: false,
						},
					]}
				/>
			)}
			{videoDocOrch && (
				<ModalSingleVideo
					isOpen={openVideoModalOrch}
					onRequestClose={() => {
						setOpenVideoModalOrch(false);
					}}
					playlist={[
						{
							video: videoFileOrch,
							title: videoDocOrch.title__v,
							description: videoDocOrch.medical_description__c,
							isWide: false,
						},
					]}
				/>
			)}
		</Layout>
	);
};

export default AnemiaCKDPage;
